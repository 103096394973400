exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-tools-mp-4-to-mp-3-converter-tsx": () => import("./../../../src/pages/tools/mp4-to-mp3-converter.tsx" /* webpackChunkName: "component---src-pages-tools-mp-4-to-mp-3-converter-tsx" */),
  "component---src-pages-tools-starmaker-downloader-tsx": () => import("./../../../src/pages/tools/starmaker-downloader.tsx" /* webpackChunkName: "component---src-pages-tools-starmaker-downloader-tsx" */)
}

