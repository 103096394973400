import * as React from "react"
import Header from "./header"
import Footer from "./footer"
import * as styles from "../styles/layout.css"

interface LayoutProps {
    children?: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const links = [
        { name: "About Us", path: "/about-us" },
        { name: "Contact Us", path: "/contact-us" },
        { name: "Privacy Policy", path: "/privacy-policy" },
        { name: "Terms of Service", path: "/terms-of-service" }
    ]

    return (
        <>
            <Header siteTitle="" links={links} />
            <div className={styles.layoutContent}>
                <main>
                    {children}
                </main>
            </div>
            <Footer links={links} />
        </>
    )
}

export default Layout