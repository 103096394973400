import { Link } from "gatsby"
import * as React from "react"
import * as styles from "../styles/footer.css"

interface FooterProps {
    links: Array<{ name: string, path: string }>
}

const Footer: React.FC<FooterProps> = ({ links }) => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerContent}>
                <ul className={styles.footerList}>
                    {links.map((link) => (
                        <li key={link.name} >
                            <Link to={link.path} className={styles.footerListItem} >
                                {link.name}
                            </Link>
                        </li>
                    ))}
                </ul>
                <div className={styles.copyrightsContainer}>
                    <div >Copyright © Vocal Archive 2024. All rights reserved
                        <br />
                        <Link to={"/privacy-policy"} className={styles.footerLink} >
                            Privacy Policy
                        </Link>
                        &nbsp;|&nbsp;
                        <Link to={"/terms-of-service"} className={styles.footerLink} >
                            Terms of Service
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer